import React from "react"
import Layout from "../components/layout.js"

export default () => (
  <div>
    <Layout>
      <p>
        We are a boutique consulting firm with expertise in modernizing
        development and operations processes. We work with both individuals and
        companies to improve their insight into the status of their development
        and deployments by leveraging cloud native tooling. We are local to
        Philadelphia and had the pleasure of working remotely with clients
        throughout the US as well as internationally-based teams. We are
        available for short and long-term contracts depending on your needs.
      </p>
      <p>
        We focus on providing technical solutions to remedy operatoral toil
        while fitting within existing business and enterprise requirements by
        leveraging open source tools and following best practices in the
        community.
      </p>
    </Layout>
  </div>
)
